import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Loader from './components/stateless/Loader';

const App = lazy(() => import('./App'));

if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_CUSTOM_NODE_ENV === 'production') {
  require('./intercom');
  require('./googleAnalytics');
}

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<Loader />}>
      <App />
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
