import React from 'react';

import './index.scss';

const Loader = ({ isSmall = false, onClick }) => (
  <div className="spinner-holder">
    <div className={isSmall ? 'spinner small' : 'spinner' }  onClick={() => onClick({ name: 'gag' })}>
      <div className="dot dot1" />
      <div className="dot dot2" />
    </div>
  </div>
);

export default Loader;
